import Vue from 'vue'
import Router from 'vue-router'
import cookies from 'js-cookie'

Vue.use(Router)

const router = new Router({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/auth',
      component: () => import('@/views/pages/Index'),
      children: [
        {
          name: 'Login',
          path: 'login',
          component: () => import('@/views/pages/Login'),
        },
        {
          name: 'Register',
          path: 'register',
          component: () => import('@/views/pages/Register'),
        },
      ],
    },
    {
      path: '/',
      component: () => import('@/views/dashboard/Index'),
      children: [
        // Dashboard
        {
          name: 'Dashboard',
          path: '',
          component: () => import('@/views/dashboard/Dashboard'),
        },
        // Leads
        {
          name: 'Leads',
          path: 'leads',
          component: () => import('@/views/dashboard/customers/Index'),
          props: { customer: 'lead' },
        },
        // Lead Detail
        {
          name: 'Lead Detail',
          path: 'leads/:id',
          redirect: 'leads/:id/dashboard',
          component: () => import('@/views/dashboard/customers/details/Index'),
          children: [
            {
              name: 'Lead Dashboard',
              path: 'dashboard',
              component: () => import('@/views/dashboard/customers/details/Dashboard'),
            },
            {
              name: 'Lead General Information',
              path: 'general',
              component: () => import('@/views/dashboard/customers/details/General'),
            },
            {
              name: 'Lead Address',
              path: 'address',
              component: () => import('@/views/dashboard/customers/details/Address'),
            },
            {
              name: 'Lead Contacts',
              path: 'contacts',
              component: () => import('@/views/dashboard/customers/details/Contacts'),
            },
            {
              name: 'Lead Files',
              path: 'files',
              component: () => import('@/views/dashboard/documents/Files'),
            },
            {
              name: 'Lead Notes',
              path: 'notes',
              component: () => import('@/views/dashboard/customers/details/Notes'),
            },
          ],
        },
        // Clients
        {
          name: 'Clients',
          path: 'clients',
          component: () => import('@/views/dashboard/customers/Index'),
          props: { customer: 'client' },
        },
        // Client Detail
        {
          name: 'Client Detail',
          path: 'clients/:id',
          redirect: 'clients/:id/dashboard',
          component: () => import('@/views/dashboard/customers/details/Index'),
          children: [
            {
              name: 'Client Dashboard',
              path: 'dashboard',
              component: () => import('@/views/dashboard/customers/details/Dashboard'),
            },
            {
              name: 'Client General Information',
              path: 'general',
              component: () => import('@/views/dashboard/customers/details/General'),
            },
            {
              name: 'Client Address',
              path: 'address',
              component: () => import('@/views/dashboard/customers/details/Address'),
            },
            {
              name: 'Client Contacts',
              path: 'contacts',
              component: () => import('@/views/dashboard/customers/details/Contacts'),
            },
            {
              name: 'Client Files',
              path: 'files',
              component: () => import('@/views/dashboard/documents/Files'),
            },
            {
              name: 'Client Notes',
              path: 'notes',
              component: () => import('@/views/dashboard/customers/details/Notes'),
            },
            {
              name: 'Client Policies',
              path: 'policies',
              component: () => import('@/views/dashboard/policies/Policies'),
              props: { fromClient: true },
            },
          ],
        },
        // Archives
        {
          name: 'Archives',
          path: 'archives',
          component: () => import('@/views/dashboard/customers/Index'),
          props: { customer: 'archive' },
        },
        // Archive Detail
        {
          name: 'Archive Detail',
          path: 'archives/:id',
          redirect: 'archives/:id/dashboard',
          component: () => import('@/views/dashboard/customers/details/Index'),
          children: [
            {
              name: 'Archive Dashboard',
              path: 'dashboard',
              component: () => import('@/views/dashboard/customers/details/Dashboard'),
            },
            {
              name: 'Archive General Information',
              path: 'general',
              component: () => import('@/views/dashboard/customers/details/General'),
            },
            {
              name: 'Archive Address',
              path: 'address',
              component: () => import('@/views/dashboard/customers/details/Address'),
            },
            {
              name: 'Archive Contacts',
              path: 'contacts',
              component: () => import('@/views/dashboard/customers/details/Contacts'),
            },
            {
              name: 'Archive Files',
              path: 'files',
              component: () => import('@/views/dashboard/documents/Files'),
            },
            {
              name: 'Archive Notes',
              path: 'notes',
              component: () => import('@/views/dashboard/customers/details/Notes'),
            },
          ],
        },
        // Documents
        {
          name: 'Documents',
          path: 'documents',
          component: () => import('@/views/dashboard/documents/Index'),
        },
        // Reports
        {
          name: 'Reports',
          path: 'reports',
          component: () => import('@/views/dashboard/reports/Index'),
        },
        // Policies
        {
          name: 'Policies',
          path: 'policies',
          component: () => import('@/views/dashboard/policies/Index'),
        },
        // Policy Detail
        {
          name: 'Policy Detail',
          path: 'policies/:id',
          redirect: 'policies/:id/general',
          component: () => import('@/views/dashboard/policies/details/Index'),
          children: [
            {
              name: 'Policy General Information',
              path: 'general',
              component: () => import('@/views/dashboard/policies/details/General'),
            },
            {
              name: 'Policy Address',
              path: 'address',
              component: () => import('@/views/dashboard/policies/details/Address'),
            },
            {
              name: 'Policy Files',
              path: 'files',
              component: () => import('@/views/dashboard/documents/Files'),
            },
          ],
        },
        // Agencies
        {
          name: 'Agencies',
          path: 'agencies',
          component: () => import('@/views/dashboard/team/Index'),
          props: { companyClass: 'agency' },
        },
        // Agency Detail
        {
          name: 'Agency Detail',
          path: 'agency/:id',
          redirect: 'agency/:id/general',
          component: () => import('@/views/dashboard/team/details/Index'),
          children: [
            {
              name: 'Agency General Information',
              path: 'general',
              component: () => import('@/views/dashboard/team/details/General'),
              props: { icon: 'mdi-umbrella', title: 'Agency Information' },
            },
            {
              name: 'Agency Address',
              path: 'address',
              component: () => import('@/views/dashboard/customers/details/Address'),
              props: { from: 'companyClass' },
            },
            {
              name: 'Agency Contacts',
              path: 'contacts',
              component: () => import('@/views/dashboard/customers/details/Contacts'),
              props: { from: 'companyClass' },
            },
            {
              name: 'Agency Files',
              path: 'files',
              component: () => import('@/views/dashboard/documents/Files'),
            },
            {
              name: 'Agency Notes',
              path: 'notes',
              component: () => import('@/views/dashboard/customers/details/Notes'),
              props: { from: 'companyClass' },
            },
          ],
        },
        // Locations
        {
          name: 'Locations',
          path: 'locations',
          component: () => import('@/views/dashboard/locations/Index'),
        },
        // Agents
        {
          name: 'Agents',
          path: 'agents',
          component: () => import('@/views/dashboard/agents/Index'),
        },
        // Agent Detail
        {
          name: 'Agent Detail',
          path: 'agents/:id',
          redirect: 'agents/:id/general',
          component: () => import('@/views/dashboard/agents/details/Index'),
          children: [
            {
              name: 'Agent General Information',
              path: 'general',
              component: () => import('@/views/dashboard/agents/details/General'),
            },
            {
              name: 'Agent Address',
              path: 'address',
              component: () => import('@/views/dashboard/customers/details/Address'),
              props: { from: 'agent' },
            },
            {
              name: 'Agent Contacts',
              path: 'contacts',
              component: () => import('@/views/dashboard/customers/details/Contacts'),
              props: { from: 'agent' },
            },
            {
              name: 'Agent Files',
              path: 'files',
              component: () => import('@/views/dashboard/documents/Files'),
            },
            {
              name: 'Agent Notes',
              path: 'notes',
              component: () => import('@/views/dashboard/customers/details/Notes'),
              props: { from: 'agent' },
            },
          ],
        },
        // Carriers
        {
          name: 'Carriers',
          path: 'carriers',
          component: () => import('@/views/dashboard/team/Index'),
          props: { companyClass: 'carrier' },
        },
        // Carrier Detail
        {
          name: 'Carrier Detail',
          path: 'carrier/:id',
          redirect: 'carrier/:id/general',
          component: () => import('@/views/dashboard/team/details/Index'),
          children: [
            {
              name: 'Carrier General Information',
              path: 'general',
              component: () => import('@/views/dashboard/team/details/General'),
              props: { icon: 'mdi-umbrella', title: 'Carrier Information' },
            },
            {
              name: 'Carrier Address',
              path: 'address',
              component: () => import('@/views/dashboard/customers/details/Address'),
              props: { from: 'companyClass' },
            },
            {
              name: 'Carrier Contacts',
              path: 'contacts',
              component: () => import('@/views/dashboard/customers/details/Contacts'),
              props: { from: 'companyClass' },
            },
            {
              name: 'Carrier Notes',
              path: 'notes',
              component: () => import('@/views/dashboard/customers/details/Notes'),
              props: { from: 'companyClass' },
            },
          ],
        },
        // Finances
        {
          name: 'Finances',
          path: 'finances',
          component: () => import('@/views/dashboard/team/Index'),
          props: { companyClass: 'finance' },
        },
        // Finance Detail
        {
          name: 'Finance Detail',
          path: 'finance/:id',
          redirect: 'finance/:id/general',
          component: () => import('@/views/dashboard/team/details/Index'),
          children: [
            {
              name: 'Finance General Information',
              path: 'general',
              component: () => import('@/views/dashboard/team/details/General'),
              props: { icon: 'mdi-umbrella', title: 'Finance Information' },
            },
            {
              name: 'Finance Address',
              path: 'address',
              component: () => import('@/views/dashboard/customers/details/Address'),
              props: { from: 'companyClass' },
            },
            {
              name: 'Finance Contacts',
              path: 'contacts',
              component: () => import('@/views/dashboard/customers/details/Contacts'),
              props: { from: 'companyClass' },
            },
            {
              name: 'Finance Notes',
              path: 'notes',
              component: () => import('@/views/dashboard/customers/details/Notes'),
              props: { from: 'companyClass' },
            },
          ],
        },
        // Map
        {
          name: 'Map',
          path: 'map',
          component: () => import('@/views/dashboard/map/Index'),
        },
        // Change Password
        {
          name: 'Change Password',
          path: 'profile/change-password',
          component: () => import('@/views/dashboard/profile/ChangePassword'),
        },
      ],
    },
    {
      path: '*',
      component: () => import('@/views/pages/Index'),
      children: [
        {
          name: '404 Error',
          path: '',
          component: () => import('@/views/pages/Error'),
        },
      ],
    },
  ],
})

router.beforeEach((to, from, next) => {
  if (to.path !== '/auth/login' && !cookies.get('crmdms-token')) {
    return next('/auth/login')
  }

  if (to.path === '/auth/login' && cookies.get('crmdms-token')) {
    return next('/')
  }
  next()
})

export default router
