import axios from 'axios'

export const items = {
  state: {
    // Initial Items
    companyClasses: [],
    companyTypes: [],
    phoneTypes: [],
    emailTypes: [],
    socialTypes: [],
    priorityTypes: [],
    temperatureTypes: [],
    countries: [],
    policyTypes: [],
    packageTypes: [],
    terms: [],
    financeOptions: [],
    billingTypes: [],
    premiumSents: [],
    userClasses: [],
    leadStatuses: [],
    clientStatuses: [],
    archiveStatuses: [],
    policyStatuses: [],
    businessLines: [],
    csrs: [],
    agents: [],
    sources: [],
    agencies: [],
    companies: [],
    users: [],

    // Dynamic Items
    customer: {},
    agent: {},
    companyClass: {},
  },

  mutations: {
    SET_ITEMS (state, { data }) {
      state[data.state] = data.items
    },

    SET_DYNAMIC_ITEM (state, { data }) {
      state[data.state] = data.item
    },

    RESET_ITEM (state, { data }) {
      Object.assign({}, state[data.state])
    },
  },

  actions: {
    async getInitials ({ commit }, mixins) {
      const promises = mixins.map(mixin => (
        axios.get(mixin.url).then(res => res.data)
      ))

      const items = await Promise.all(promises)
      mixins.forEach((mixin, index) => {
        commit({ type: 'SET_ITEMS', data: { state: mixin.item, items: items[index] } })
      })
    },

    /* Input Param type sample: {
      url: apiurl,
      item: state
    }
    */
    async getItem ({ commit, dispatch }, item) {
      try {
        const response = await axios.get(item.url)
        commit({ type: 'SET_DYNAMIC_ITEM', data: { state: item.item, item: response.data } })
      } catch (error) {
        commit({ type: 'RESET_ITEM', data: { state: item.item } })
        dispatch('showSnackBar', { text: error, color: 'error' }, { root: true })
      }
    },
  },
}
