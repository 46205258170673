import MobileDetect from 'mobile-detect'

const md = new MobileDetect(window.navigator.userAgent)

function getIsMobile () {
  return !!md.mobile() || !!md.phone()
}

export const sidebar = {
  state: {
    barColor: 'rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)',
    barImage: require('@/assets/sidebar.png'),
    drawer: null,
    isMobile: getIsMobile(),
    profileImage: '',
  },
  mutations: {
    SET_BAR_IMAGE (state, payload) {
      state.barImage = payload
    },
    SET_DRAWER (state, payload) {
      state.drawer = payload
    },
    SET_SCRIM (state, payload) {
      state.barColor = payload
    },
    SET_PROFILE_IMAGE (state, payload) {
      state.profileImage = payload
    },
  },
}
