import axios from 'axios'
import cookies from 'js-cookie'
import router from '../router'

export const authentication = {
  state: {
    token: null,
  },
  mutations: {
    SET_TOKEN (state, data) {
      state.token = data
    },
  },
  actions: {
    setToken ({ commit }, data) {
      commit('SET_TOKEN', data.token)
      axios.defaults.headers.common.Authorization = 'Bearer ' + data.token
      cookies.set('crmdms-token', data.token, { expires: 7 })
      cookies.set('unlocked', 1, { expires: 7 })
    },
    logout ({ commit }) {
      delete axios.defaults.headers.common.Authorization
      cookies.remove('crmdms-token')
      cookies.remove('unlocked')
      this.reset()
    },
    check ({ commit }, token) {
      if (token) {
        const makeDefault = () => {
          delete axios.defaults.headers.common.Authorization
          cookies.remove('crmdms-token')
          this.reset()
          router.push('/auth/login')
        }

        axios.defaults.headers.common.Authorization = 'Bearer ' + token

        return new Promise((resolve, reject) => {
          axios.get('auth/check')
            .then(res => {
              if (res.data.success) {
                resolve()
              } else {
                makeDefault()
              }
            })
            .catch(err => {
              reject(err)
              makeDefault()
            })
        })
      }
    },
  },
}
