import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import i18n from '@/i18n'
import '@/sass/overrides.sass'
import ListStatusIcon from '@/custom/ListStatusIcon'
import WritingCarrier from '@/custom/WritingCarrier'
import ShieldClock from '@/custom/ShieldClock'
import ShieldUmbrella from '@/custom/ShieldUmbrella'

Vue.use(Vuetify)

const theme = {
  primary: '#732725',
  secondary: '#174446',
  success: '#299330',
  accent: '#206D71',
  info: '#27A2A8',
  warning: '#FF953A',
  error: '#BB3835',
}

export default new Vuetify({
  lang: {
    t: (key, ...params) => i18n.t(key, params),
  },
  theme: {
    themes: {
      dark: theme,
      light: theme,
    },
    options: {
      customProperties: true,
    },
  },
  icons: {
    values: {
      listStatus: { component: ListStatusIcon },
      writingCarrier: { component: WritingCarrier },
      shieldClock: { component: ShieldClock },
      shieldUmbrella: { component: ShieldUmbrella },
    },
  },
})
