import { render, staticRenderFns } from "./ShieldClock.vue?vue&type=template&id=6de2c80d&"
import script from "./ShieldClock.vue?vue&type=script&lang=js&"
export * from "./ShieldClock.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports