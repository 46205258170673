<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    version="1.1"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path
      style="fill: rgba(0, 0, 0, 0.54)"
      d="M 20.94,16.02
    C 21.08,15.88 21.08,15.66 20.94,15.53
      20.94,15.53 20.13,14.72 20.13,14.72
      20.01,14.59 19.78,14.59 19.65,14.72
      19.65,14.72 19.01,15.35 19.01,15.35
      19.01,15.35 20.31,16.65 20.31,16.65M 14.83,19.54
    C 14.83,19.54 14.83,20.83 14.83,20.83
      14.83,20.83 16.13,20.83 16.13,20.83
      16.13,20.83 19.94,17.01 19.94,17.01
      19.94,17.01 18.65,15.72 18.65,15.72
      18.65,15.72 14.83,19.54 14.83,19.54 Z
    M 4.00,10.00
    C 4.00,10.00 7.00,10.00 7.00,10.00
      7.00,10.00 7.00,17.00 7.00,17.00
      7.00,17.00 4.00,17.00 4.00,17.00
      4.00,17.00 4.00,10.00 4.00,10.00 Z
    M 10.00,10.00
    C 10.00,10.00 13.00,10.00 13.00,10.00
      13.00,10.00 13.00,14.68 13.00,14.68
      12.54,15.37 12.22,16.15 12.08,17.00
      12.08,17.00 10.00,17.00 10.00,17.00
      10.00,17.00 10.00,10.00 10.00,10.00M 2.00,22.00
    C 2.00,22.00 2.00,19.00 2.00,19.00
      2.00,19.00 12.08,19.00 12.08,19.00
      12.27,20.14 12.79,21.17 13.53,22.00
      13.53,22.00 2.00,22.00 2.00,22.00M 16.00,10.00
    C 16.00,10.00 19.00,10.00 19.00,10.00
      19.00,10.00 19.00,12.08 19.00,12.08
      19.00,12.08 18.00,12.00 18.00,12.00
      17.30,12.00 16.63,12.12 16.00,12.34
      16.00,12.34 16.00,10.00 16.00,10.00M 11.50,1.00
    C 11.50,1.00 21.00,6.00 21.00,6.00
      21.00,6.00 21.00,8.00 21.00,8.00
      21.00,8.00 2.00,8.00 2.00,8.00
      2.00,8.00 2.00,6.00 2.00,6.00
      2.00,6.00 11.50,1.00 11.50,1.00"
    />
  </svg>
</template>

<script>
  export default {

  }
</script>
