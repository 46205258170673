<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    version="1.1"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path
      style="fill: rgba(0, 0, 0, 0.54)"
      d="M 11.98,17.21
      C 15.03,17.21 17.51,14.73 17.51,11.67
        17.51,8.62 15.03,6.14 11.98,6.14
        8.92,6.14 6.45,8.62 6.45,11.67
        6.45,14.73 8.92,17.21 11.98,17.21M 11.98,4.76
      C 15.80,4.76 18.90,7.85 18.90,11.67
        18.90,15.49 15.80,18.59 11.98,18.59
        8.15,18.59 5.06,15.48 5.06,11.67
        5.06,7.85 8.16,4.76 11.98,4.76M 12.32,8.21
      C 12.32,8.21 12.32,11.85 12.32,11.85
        12.32,11.85 15.44,13.69 15.44,13.69
        15.44,13.69 14.92,14.54 14.92,14.54
        14.92,14.54 11.29,12.36 11.29,12.36
        11.29,12.36 11.29,8.21 11.29,8.21
        11.29,8.21 12.32,8.21 12.32,8.21 Z
      M 12.00,1.00
      C 12.00,1.00 3.00,5.00 3.00,5.00
        3.00,5.00 3.00,11.00 3.00,11.00
        3.00,16.55 6.84,21.74 12.00,23.00
        17.16,21.74 21.00,16.55 21.00,11.00
        21.00,11.00 21.00,5.00 21.00,5.00
        21.00,5.00 12.00,1.00 12.00,1.00 Z"
    />
  </svg>
</template>

<script>
  export default {

  }
</script>
