<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    version="1.1"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path
      style="fill: rgba(0, 0, 0, 0.54)"
      d="M 11.94,4.87
      C 15.56,4.87 18.50,7.80 18.50,11.42
        18.50,11.42 12.67,11.42 12.67,11.42
        12.67,11.42 12.67,17.25 12.67,17.25
        12.67,18.46 11.69,19.44 10.49,19.44
        9.28,19.44 8.30,18.46 8.30,17.25
        8.30,17.25 8.30,16.52 8.30,16.52
        8.30,16.52 9.76,16.52 9.76,16.52
        9.76,16.52 9.76,17.25 9.76,17.25
        9.76,17.65 10.08,17.98 10.49,17.98
        10.89,17.98 11.22,17.65 11.22,17.25
        11.22,17.25 11.22,11.42 11.22,11.42
        11.22,11.42 5.39,11.42 5.39,11.42
        5.39,7.80 8.32,4.87 11.94,4.87
        11.94,4.87 11.94,4.87 11.94,4.87 Z
      M 12.00,1.00
      C 12.00,1.00 3.00,5.00 3.00,5.00
        3.00,5.00 3.00,11.00 3.00,11.00
        3.00,16.55 6.84,21.74 12.00,23.00
        17.16,21.74 21.00,16.55 21.00,11.00
        21.00,11.00 21.00,5.00 21.00,5.00
        21.00,5.00 12.00,1.00 12.00,1.00 Z"
    />
  </svg>
</template>

<script>
  export default {

  }
</script>
